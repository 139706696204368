import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet';
import Footer from '../../components/Footer/index';
import Header from '../../components/Header/Header';
import topImg from '../../assets/img/terms-conditions.jpg';
import countryService from '../../services/countryService';

import styles from './styles.module.scss';

const TermsAndConditions = () => {
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    const countryCode = countryService.getCurrentCountryCode();

    import(`./markdowns/${countryCode}.md`)
      .then((module) => fetch(module.default).then((res) => res.text()))
      .then((text) => setMarkdown(text));
  }, []);

  return (
    <div>
      <Header />
      <Helmet>
        <title>Términos y condiciones - VacantED</title>
      </Helmet>
      <div className={styles.terms__and__conditions__content}>
        <div className={styles.img_content}>
          <img className={styles.img_file} src={topImg} alt="computer" />
        </div>
        <div className={styles.text_content}>
          <ReactMarkdown>{markdown}</ReactMarkdown>
        </div>
      </div>
      <div className="foter_wrapper">
        <Footer />
      </div>
    </div>
  );
};

export default TermsAndConditions;
