import React from 'react';
import PropTypes from 'prop-types';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { IconButton, InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import countryService from 'services/countryService';

const validUyCellPhoneRegex = /^\+598 0?9\d \d{3} \d{3}$/;

const PhoneInput = ({
  label, data, setData, errorStatus,
}) => {
  const handlePhoneChange = (newValue) => {
    const newData = {
      phone: newValue,
      isValid: newValue.startsWith('+598')
        ? validUyCellPhoneRegex.test(newValue)
        : matchIsValidTel(newValue),
      curatedPhone: newValue.startsWith('+598')
        ? newValue.replace(/\s/g, '').replace('+5980', '+598')
        : newValue.replace(/\s/g, ''),
    };
    setData(newData);
  };

  const handleClear = () => {
    setData({
      phone: '',
      isValid: true,
      curatedPhone: '',
    });
  };

  return (
    <MuiTelInput
      InputProps={{
        // className: 'congrats__form__input',
        size: 'medium',
        name: 'phone',
        endAdornment: data?.phone ? (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClear}
              edge="end"
              size="small"
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      fullWidth
      label={label}
      defaultCountry={countryService.getCurrentCountryCode().toUpperCase()}
      value={data?.phone ?? ''}
      onChange={handlePhoneChange}
      error={errorStatus}
      helperText={errorStatus ? 'Número inválido' : ''}
    />
  );
};

PhoneInput.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.shape({
    phone: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  setData: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  errorStatus: PropTypes.bool,
};

export default PhoneInput;
