import axios from 'axios';
import { getUrl } from 'urlConfig';
import { parsedDataInStorage, removeDataInStorage } from 'utils/Storage/storageFunctions';

const apiUrl = getUrl('api');

const api = axios.create({ baseURL: apiUrl });

api.checkFamilySessionIsValid = async (loginData) => {
  const dataToValidate = loginData || parsedDataInStorage('vacanted');
  if (!dataToValidate) return false;

  const { token, user } = dataToValidate;
  if (!user) {
    console.log('No user data found in storage');
    return false;
  }
  const { email, role_id: roleId } = user;
  if (roleId !== 5) return false;

  const data = { email };
  const valid = await api.post('/user/auth', data, {
    headers: {
      authorization: token,
    },
  }).then((response) => response?.status === 202)
    .catch(() => false);

  if (!valid) removeDataInStorage('vacanted');
  return valid;
};

api.getAges = async () => api.get('/age')
  .then((response) => response.data)
  .catch(() => []);

api.sendInteraction = async (schoolId, body) => {
  const storageData = parsedDataInStorage('vacanted');

  const config = storageData?.token ? {
    headers: {
      authorization: storageData.token,
    },
  } : undefined;
  return api.post(`/school/interact/${schoolId}`, body, config);
};

export default api;
