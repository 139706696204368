import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  ListItemText,
  Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
// import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import { useUserData } from 'hooks/UserContext';
import api from 'services/api';
import textsService from 'services/textsService';
import { parsedDataInStorage } from 'utils/Storage/storageFunctions';
import PhoneInput from '../../pages/Experience/src/components/phone-input';

const MODAL_STATES = {
  LOADING: 'loading',
  FORM: 'form',
  SUCCESS: 'success',
  ERROR: 'error',
};

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/^[a-zA-ZÀ-ÿ]{3,}(\s+[a-zA-ZÀ-ÿ]{3,})+/, 'Debes ingresar nombre y apellido')
    .required('Debes ingresar su nombre completo'),
  email: Yup.string()
    .email('Correo inválido')
    .required('Debes ingresar tu email'),
  messageToSchool: Yup.string()
    .required('Debes ingresar un mensaje'),
  educationalLevels: Yup.array()
    .min(1, 'Debes seleccionar al menos un nivel educativo'),
  seasonId: Yup.number()
    .required('Debes seleccionar el año de ingreso'),
  accept_the_terms: Yup.boolean()
    .oneOf([true], 'Debes aceptar los Términos y Condiciones'),
});

const getAvailableSeasonOptions = () => api.get('/seasons/active')
  .then(({ data: apiSeasons }) => {
    if (!apiSeasons) return [];

    const options = [];
    if (apiSeasons.current) {
      const { id, name } = apiSeasons.current;
      options.push(
        {
          id,
          name,
          label: `${name} (En curso)`,
        },
      );
    }
    if (apiSeasons.next) {
      const { id, name } = apiSeasons.next;
      options.push(
        {
          id,
          name,
          label: `${name} (Próximo comienzo)`,
        },
      );
    }
    options.push(
      {
        id: 0,
        name: 'OTHER',
        label: 'Otro',
      },
    );
    return options;
  })
  .catch(() => console.error('Failed to get seasons') || []);

function PrettyContactModal({
  onClose: onCloseProp,
  schoolId,
  modalAction = 'question', // 'question' or 'visit'
  candidateSource = null,
}) {
  const vacantedData = parsedDataInStorage('vacanted');
  const loggedUser = vacantedData?.user;
  const initialName = loggedUser?.name?.replace('Usuario_Vacanted', '')?.replace('user_vacanted', '');

  const { userData, activeExperience, updateActiveExperience } = useUserData();

  const [modalState, setModalState] = useState(MODAL_STATES.LOADING);
  const [schoolData, setSchoolData] = useState(null);
  const [seasons, setSeasons] = useState([]);
  const [error, setError] = useState();
  const [result, setResult] = useState();

  const onClose = () => {
    if ((result === 'experience_created' || result === 'candidate') && userData?.loginStatus) {
      updateActiveExperience();
    }
    onCloseProp();
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [schoolResponse, seasonsResponse] = await Promise.all([
          api.get(`/school/${schoolId}/interaction_info`),
          getAvailableSeasonOptions(),
        ]);

        setSchoolData(schoolResponse.data);
        setSeasons(seasonsResponse);
        setModalState(MODAL_STATES.FORM);
      } catch (err) {
        console.error('Failed to fetch initial data:', err);
        setError({
          message: 'No pudimos cargar la información necesaria. Por favor, intente nuevamente.',
        });
        setModalState(MODAL_STATES.ERROR);
      }
    };

    fetchInitialData();
  }, []);

  const getSuccessMessage = () => {
    const params = {
      schoolName: schoolData?.name,
    };
    if (result === 'pending_validation') {
      return textsService.getLocalizedText(
        textsService.TEXT_KEYS.INTERACTION_PENDING_VALIDATION, params,
      );
    }
    if (result === 'candidate') {
      return textsService.getLocalizedText(
        textsService.TEXT_KEYS.INTERACTION_ADDED_CANDIDATE, params,
      );
    }
    return textsService.getLocalizedText(textsService.TEXT_KEYS.INTERACTION_CREATED, params);
  };

  const getErrorMessage = (errorResponseBody) => {
    if (errorResponseBody?.error && textsService.TEXT_KEYS[errorResponseBody.error]) {
      return textsService.getLocalizedText(textsService.TEXT_KEYS[errorResponseBody.error]);
    }
    return textsService.getLocalizedText(textsService.TEXT_KEYS.INTERACTION_FAILED);
  };

  const getPreselectedLevelsFromExperience = () => {
    const schoolLevelsMatchingExperience = schoolData?.educationalLevels?.filter(
      (level) => activeExperience?.context?.basic?.levels?.ages?.some(
        (expAge) => expAge.level_name === level.levelName,
      ),
    )?.map((level) => level.levelName);
    return schoolLevelsMatchingExperience || [];
  };

  const renderContent = () => {
    switch (modalState) {
      case MODAL_STATES.LOADING:
        return (
          <Box display="flex" flexDirection="column" alignItems="center" py={4}>
            <CircularProgress size={48} />
            <Typography variant="body1" mt={2}>
              Cargando información...
            </Typography>
          </Box>
        );

      case MODAL_STATES.SUCCESS:
        return (
          <Box display="flex" flexDirection="column" alignItems="center" py={4}>
            <CheckCircleIcon color="success" sx={{ fontSize: 64, mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              ¡Listo!
            </Typography>
            <Typography variant="body1" textAlign="center" color="text.secondary">
              {getSuccessMessage()}
            </Typography>
            <Button
              variant="contained"
              onClick={onClose}
              sx={{ mt: 3 }}
            >
              Cerrar
            </Button>
          </Box>
        );

      case MODAL_STATES.ERROR:
        return (
          <Box display="flex" flexDirection="column" alignItems="center" py={4}>
            <ErrorIcon color="error" sx={{ fontSize: 64, mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Ha ocurrido un error
            </Typography>
            <Typography variant="body1" textAlign="center" color="text.secondary">
              {error?.message}
            </Typography>
            {modalState === MODAL_STATES.ERROR && !error?.isFinal ? (
              <Button
                variant="contained"
                onClick={() => setModalState(MODAL_STATES.FORM)}
                sx={{ mt: 3 }}
              >
                Intentar nuevamente
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={onClose}
                sx={{ mt: 3 }}
              >
                Cerrar
              </Button>
            )}
          </Box>
        );

      default:
        return (
          <Formik
            initialValues={{
              name: initialName || '',
              email: loggedUser?.email || '',
              phoneData: loggedUser?.personal_phone
                ? {
                  curatedPhone: loggedUser.personal_phone,
                  phone: loggedUser.personal_phone,
                  isValid: true,
                }
                : null,
              messageToSchool: '',
              educationalLevels: getPreselectedLevelsFromExperience(),
              seasonId: activeExperience?.season_id || null,
              accept_the_terms: false,
            }}
            validationSchema={validationSchema}
            validateOnBlur
            validateOnChange={false}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const body = {
                  userEmail: values.email,
                  userName: values.name,
                  userPhoneNumber: (values.phoneData?.isValid
                    && values.phoneData.curatedPhone !== loggedUser?.personal_phone)
                    ? values.phoneData?.curatedPhone
                    : undefined,
                  educationalLevels: values.educationalLevels.map((levelName) => {
                    const fullLevel = schoolData?.educationalLevels?.find(
                      (level) => level.levelName === levelName,
                    );
                    return {
                      age: fullLevel.age,
                      levelName,
                    };
                  }),
                  season: values.seasonId
                    ? seasons.find((season) => season.id === values.seasonId)
                    : null,
                  messageToSchool: values.messageToSchool,
                  interactionType: modalAction === 'visit' ? 'INTERVIEW_REQUEST' : 'CONTACT',
                  candidateSource,
                };

                const response = await api.sendInteraction(schoolId, body);
                if (response?.status === 200) {
                  const { data: { result: interactionResult } } = response;
                  setResult(interactionResult);
                  setModalState(MODAL_STATES.SUCCESS);
                }
              } catch (err) {
                console.error('Submit failed:', err);
                const newError = {};
                if (err?.response) {
                  newError.isFinal = err.response.status === 400;
                  newError.message = getErrorMessage(err.response.data);
                } else {
                  newError.message = getErrorMessage();
                }
                setError(newError);
                setModalState(MODAL_STATES.ERROR);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({
              errors, touched, values, handleChange, handleBlur, isSubmitting, setFieldValue,
            }) => (
              <Form>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Nombre completo"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />

                  {!loggedUser?.email && (
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label="Correo electrónico"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  )}

                  <PhoneInput
                    label="WhatsApp (Se compartirá con el centro)"
                    data={values.phoneData}
                    setData={(newData) => setFieldValue('phoneData', newData)}
                    errorStatus={
                      values.phoneData
                      && !values.phoneData?.isValid
                    }
                  />

                  <FormControl
                    fullWidth
                    error={touched.educationalLevels && Boolean(errors.educationalLevels)}
                  >
                    <InputLabel>Niveles educativos de interés</InputLabel>
                    <Select
                      multiple
                      id="educationalLevels"
                      name="educationalLevels"
                      value={values.educationalLevels}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Niveles educativos de interés"
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {schoolData?.educationalLevels?.map((level) => (
                        <MenuItem key={level.levelName} value={level.levelName}>
                          <Checkbox checked={values.educationalLevels.includes(level.levelName)} />
                          <ListItemText primary={level.levelName} />
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.educationalLevels && errors.educationalLevels && (
                      <FormHelperText>{errors.educationalLevels}</FormHelperText>
                    )}
                  </FormControl>

                  <FormControl
                    fullWidth
                    error={touched.seasonId && Boolean(errors.seasonId)}
                  >
                    <InputLabel>Año de ingreso</InputLabel>
                    <Select
                      id="seasonId"
                      name="seasonId"
                      value={values.seasonId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Año de ingreso"
                    >
                      {seasons.map((season) => (
                        <MenuItem key={season.id} value={season.id}>
                          {season.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.seasonId && errors.seasonId && (
                      <FormHelperText>{errors.seasonId}</FormHelperText>
                    )}
                  </FormControl>

                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    id="messageToSchool"
                    name="messageToSchool"
                    label="Mensaje"
                    value={values.messageToSchool}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.messageToSchool && Boolean(errors.messageToSchool)}
                    helperText={touched.messageToSchool && errors.messageToSchool}
                  />

                  <FormControlLabel
                    control={(
                      <Checkbox
                        id="accept_the_terms"
                        name="accept_the_terms"
                        checked={values.accept_the_terms}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    )}
                    label={(
                      <>
                        Acepto los
                        {' '}
                        <a href="/terms-and-conditions" target="_blank">Términos y condiciones</a>
                        {' '}
                        y las
                        {' '}
                        <a href="/privacy-policies" target="_blank">Políticas de privacidad</a>
                        .
                      </>
                    )}
                  />
                  {touched.accept_the_terms && errors.accept_the_terms && (
                    <FormHelperText error>
                      {errors.accept_the_terms}
                    </FormHelperText>
                  )}

                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Enviar'
                    )}
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        );
    }
  };

  return (
    <Dialog
      open
      onClose={modalState === MODAL_STATES.FORM ? onClose : undefined}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" flexGrow={1} flexDirection="column" alignItems="center" justifyContent="center">
            <Typography variant="h5">
              {modalAction === 'visit'
                ? 'Solicitud de entrevista'
                : 'Consulta'}
            </Typography>
            <Typography variant="h7" fontSize="1.2rem" fontWeight="bold">
              {/* <SchoolRoundedIcon /> */}
              {schoolData?.name || ''}
            </Typography>
          </Box>
          {modalState !== MODAL_STATES.LOADING && (
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {renderContent()}
      </DialogContent>
    </Dialog>
  );
}

PrettyContactModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  schoolId: PropTypes.string.isRequired,
  modalAction: PropTypes.string,
  candidateSource: PropTypes.string,
};

PrettyContactModal.defaultProps = {
  modalAction: 'question',
  candidateSource: null,
};

export default PrettyContactModal;
