import React from 'react';
import PropTypes from 'prop-types';
import ReactStars from 'react-rating-stars-component';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useUserData } from 'hooks/UserContext';
import { getAgesMessageToShow } from 'utils/schools/schoolFunctions';
import { selectMiniCard } from '../../hooks/SchoolProfileContext/SchoolProfileSlice';
import miniCardConstants from './miniCardConstants';
import minicardStyles from './MiniCardStyles.module.css';

const MiniCard = ({ isFetching, positioning }) => {
  if (isFetching) return <>...cargando </>;

  const { appLanguage: lan } = useUserData();

  const miniCard = useSelector(selectMiniCard);
  const {
    neighborhood,
    city,
    logo,
    name,
    verified,
    address,
    rating,
    schoolManagement,
    schedules,
    userRatingsQty,
  } = miniCard;

  // const windowSize = useWindowSize();

  return (
    <div
      className={minicardStyles.miniCard__card}
      style={{ marginLeft: `${positioning}px` }}
    >
      <div className={minicardStyles.miniCard__firstRow}>
        <img src={logo || 'https://images.vacanted.com.uy/logos/vacanted_logo_min.png'} alt="logo del centro" />
        <div className={minicardStyles.miniCard__content}>
          <p className={minicardStyles.mainCard__heading}>{name}</p>
          <p className={minicardStyles.mainCard__subHeading}>
            {`${schoolManagement.name} de Educación ${getAgesMessageToShow(schedules)}`}
          </p>
          <p className={minicardStyles.locations}>
            <span>
              <FaMapMarkerAlt size={17} color="#36a7eb" />
              {' '}
              {`${address}, ${neighborhood.name}, ${city.name}`}
            </span>
            {/* <span>
              <FaMapMarkerAlt size={16} color="#36a7eb" />
              <FaMapMarkerAlt size={14} color="#36a7eb" />
              {` ${calculateDistance(usedCoordinates, { latitude, longitude })} km`}
            </span> */}
          </p>
          <div className={minicardStyles.miniCard__ratings} title="Fuente: Google™">
            <ReactStars
              size={17}
              half={false}
              value={Number(rating) || 5}
              edit={false}
            />
            <p>{`(${userRatingsQty}) reseñas`}</p>
          </div>
        </div>
      </div>
      {!!verified && (
        <div className={minicardStyles.miniCard__secondRow}>
          <div className={minicardStyles.verifiedMsg__container}>
            <p className={minicardStyles.verifiedMsg}>
              {miniCardConstants.verifiedInstitucionMsg[lan]}
            </p>
            <RiCheckboxCircleFill size={30} color="#0096ed" />
          </div>
        </div>
      )}
    </div>
  );
};

MiniCard.defaultProps = {
  vacantestCoords: {
    latitude: 0,
    longitude: 0,
  },
};

MiniCard.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  positioning: PropTypes.number.isRequired,
  vacantestCoords: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }),
};

export default MiniCard;
