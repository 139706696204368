/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { BsFillArrowLeftSquareFill, BsFillArrowRightSquareFill } from 'react-icons/bs';
import { useUserData } from 'hooks/UserContext';
import { useFetchSchoolQuery } from 'hooks/SchoolProfileContext/SchoolAPISlice';
import {
  selectSchoolInfo, selectSchool,
} from 'hooks/SchoolProfileContext/SchoolProfileSlice';
import { useSearch } from 'hooks/SearchContext';
// import { registerAnalytics } from 'Authentication/Data/Services/firebase/index';
import Services from 'services/Services';
import api from 'services/api';
import MiniCard from 'components/MiniCard/MiniCard';
import PrettyContactModal from 'components/PrettyContactModal';
import CannotPostulateModal from 'components/ContactModal/CannotPostulateModal';
import { Helmet } from 'react-helmet';
import Header from 'components/Header/Header';
import NotLoggedModal from 'components/NotLogged';
// import sessionStorageServices from 'services/sessionStorageServices';
import countryService from 'services/countryService';
import SliderGallery from 'components/SliderGallery';
// import { getUrl } from 'urlConfig';
import AsideScroll from './AsideScroll/AsideScroll';
/* import ProfileSection from './ProfileSection/ProfileSection'; */
import UpperLeftActions from './ProfileSection/UpperLeftActions/upperLeftActions';
import styles from './styles.module.css';
import Description from './ProfileSection/Description/Description';
import MainFeatures from './ProfileSection/MainFeatures/MainFeatures';

import ExtracurricularActivities from './ProfileSection/ExtracurricularActivities/ExtracurricularActivities';
import SecuritiesMeasures from './ProfileSection/SecuritiesMeasures/SecuritiesMeasures';
import InstalacionsActivities from './ProfileSection/InstalacionsActivities/InstalacionsActivities';
import EventsActivities from './ProfileSection/EventsActivities/EventsActivities';
import AwardsRecognitions from './ProfileSection/AwardsRecognitions/AwardsRecognitions';
import CurricularAdaptation from './ProfileSection/CurricularAdaptation/CurricularAdaptation';
import IncludedServices from './ProfileSection/IncludedServices/IncludedServices';
import EducationalLevels from './ProfileSection/EducationalLevels/EducationalLevels';
import Languages from './ProfileSection/Languages/Languages';
import Religion from './ProfileSection/Religion';
import GoogleReviews from './ProfileSection/GoogleReviews/GoogleReviews';
import Footer from '../../components/Footer';
/* import AffinityGraph from './ProfileSection/VacantestGraph/AffinityGraph'; */
import SchoolProfileMap from './ProfileSection/Map/SchoolProfileMap';
import asideSessions/* , { schoolProfileConstants } */ from './schoolProfileConstants';

import ExperienceButton from '../Experience/src/components/button';
import AddRemoveCandidateButton from '../Experience/src/components/buttons/add-remove-candidate';
import AppComponents from '../../constants/components';

const SchoolProfile = ({ id }) => {
  const bottomBar = useMediaQuery({ query: '(max-width: 740px)' });
  const location = useLocation();
  const currentCountryId = countryService.getCurrentCountryId();
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [schoolCanBeContacted, setSchoolCanBeContacted] = useState(false);
  const [notLoggedMsg, setNotLoggedMsg] = useState('');
  const [notLoggedAction, setNotLoggedAction] = useState('');
  const [isCannotPostulateModalOpen, setIsCannotPostulateModalOpen] = useState(false);
  const [selectedModalAction, setSelectedModalAction] = useState('');
  const [componentFrom, setComponentFrom] = useState();
  const [hasGallery, setHasGallery] = useState([]);
  const { isFetching, isError } = useFetchSchoolQuery(id);
  const { /* setNotLoggedOpen,  */setUsedCoordinates } = useSearch();
  const { userData, activeExperience } = useUserData();
  const { loginStatus } = userData;

  const schoolInfo = useSelector(selectSchoolInfo);
  const schoolObj = useSelector(selectSchool);

  // add ref to the sections
  const basicInfoRef = useRef(null);
  const mainFeaturesRef = useRef(null);
  const educationalLevelsRef = useRef(null);
  const religionRef = useRef(null);
  const languagesRef = useRef(null);
  const mapRef = useRef(null);
  const reviewRef = useRef(null);
  const flkty = useRef(null);
  const extracurricularActivitiesRef = useRef(null);
  const curricularAdaptationRef = useRef(null);
  const includedServicesRef = useRef(null);
  const securitiesMeasuresRef = useRef(null);
  const instalacionsActivitiesRef = useRef(null);
  const eventsActivitiesRef = useRef(null);
  const awardsRecognitionsRef = useRef(null);

  const auxSections = asideSessions;
  const basicInfoSection = auxSections.find((s) => /inform/i.test(s.name));
  const mainFeaturesSection = auxSections.find((s) => /caracter/i.test(s.name));
  const religionSection = auxSections.find((s) => /Relig/i.test(s.name));
  const languageSection = auxSections.find((s) => /idiomas/i.test(s.name));
  const extracurricularActivitiesSection = auxSections.find((s) => /xtracurriculares/i.test(s.name));

  const curricularAdapationSection = auxSections.find((s) => /adaptación/i.test(s.name));
  const includedServicesSection = auxSections.find((s) => /servicios/i.test(s.name));
  const mapSection = auxSections.find((s) => /geogr/i.test(s.name));
  const reviewSection = auxSections.find((s) => /eseñas/i.test(s.name));

  const securitiesMeasures = auxSections.find((s) => /seguridad/i.test(s.name));
  const instalacionsActivities = auxSections.find((s) => /instalaciones/i.test(s.name));
  const eventsActivities = auxSections.find((s) => /eventos/i.test(s.name));
  const awardsRecognitions = auxSections.find((s) => /reconocimientos/i.test(s.name));
  const educationalLevels = auxSections.find((s) => /propuesta/i.test(s.name));

  basicInfoSection.ref = basicInfoRef;
  mainFeaturesSection.ref = mainFeaturesRef;
  religionSection.ref = religionRef;
  languageSection.ref = languagesRef;
  extracurricularActivitiesSection.ref = extracurricularActivitiesRef;
  curricularAdapationSection.ref = curricularAdaptationRef;
  includedServicesSection.ref = includedServicesRef;
  mapSection.ref = mapRef;
  reviewSection.ref = reviewRef;
  securitiesMeasures.ref = securitiesMeasuresRef;
  instalacionsActivities.ref = instalacionsActivitiesRef;
  eventsActivities.ref = eventsActivitiesRef;
  awardsRecognitions.ref = awardsRecognitionsRef;
  educationalLevels.ref = educationalLevelsRef;

  const handleAction = (action) => {
    // ToDo: use componentFrom data to request interview with referral data
    setSelectedModalAction(action);
    setIsContactModalOpen(true);
  };

  useEffect(() => {
    if (schoolObj?.id === Number(id)) {
      // Then data is loaded
      const schoolIsActive = !!schoolObj.active;
      const schoolContactDisabled = !!schoolObj.contact_disabled;
      const schoolIsNotPublic = !!schoolObj?.public && schoolObj.public.is_public === false;
      const schoolCanBeContactedResult = schoolIsNotPublic
        && !schoolContactDisabled
        && schoolIsActive;
      setSchoolCanBeContacted(schoolCanBeContactedResult);

      const searchParams = new URLSearchParams(location.search);

      const fromParam = searchParams.get('from');
      if (fromParam) {
        const from = AppComponents.findByName(fromParam);
        if (from) {
          setComponentFrom(from);
        }
      }

      if (schoolCanBeContactedResult) {
        const action = searchParams.get('action');
        if (action) {
          handleAction(action);
        }
      }

      window.history.replaceState(null, '', `${window.location.pathname}`);
      location.search = '';
    }
  }, [schoolObj?.id]);

  useEffect(() => {
    if (schoolObj?.city?.latitude && schoolObj?.city?.longitude) {
      setUsedCoordinates({
        latitude: schoolObj.city.latitude,
        longitude: schoolObj.city.longitude,
      });
    }
  }, [schoolObj.city]);

  const getGallery = async () => {
    const imagesGallery = await api.get(`/school/gallery/${id}`);
    const { data } = imagesGallery;
    return setHasGallery(data);
  };

  useEffect(() => {
    setIsLoading(true);
    getGallery().finally(() => setIsLoading(false));
  }, [id]);

  useEffect(() => {
    // if user is logged in, check for vacantest location inside schoolAffinity
    if (schoolInfo.schoolAffinity) {
      setUsedCoordinates(schoolInfo.schoolAffinity.location);
    }
  }, [schoolInfo.schoolAffinity]);

  /* const verifyValidDates = () => {
    let calendarArr = schoolInfo.calendar;
    calendarArr = calendarArr.filter((c) => Date.parse(c.date) > Date.now());
    return calendarArr.length < 1;
  }; */

  const scrollToRef = (ref) => {
    if (ref) window.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' });
  };

  /* function handleOpenContactModal() {
    setIsContactModalOpen(true);
  } */

  function handleCloseContactModal() {
    setIsContactModalOpen(false);
  }

  /* function redirectToPostulation() {
    navigate({
      pathname: '/postulate',
      state: {
        schoolId: id,
        schoolName: schoolInfo.schoolName,
      },
    });
  } */

  /* function handleOpenCannotPostulateModal() {
    setIsCannotPostulateModalOpen(true);
  } */

  async function handleCloseCannotPostulateModal(check) {
    if (loginStatus && !schoolInfo.postulate) {
      const alert = check;
      const postulationAlert = {
        school_id: id,
        postulated: false,
        alert,
      };

      await Services.savePostulationAlert(postulationAlert);
    }

    setIsCannotPostulateModalOpen(false);
  }

  /* function isPostulationAllowed() {
    if (!loginStatus) {
      sessionStorageServices.saveItem('userFrom', `schoolProfile-${id}`);
      return setNotLoggedOpen(true);
    }

    if (loginStatus && !schoolInfo.postulate) {
      return handleOpenCannotPostulateModal();
    }

    return redirectToPostulation();
  } */

  /* async function handleSearch() {
    navigate(`/search/radius?latitude=${coords.lat}&longitude=${coords.lng}`);
  } */

  // useEffect(async () => {
  //   const schoolId = await schoolObj.id;
  //   if (schoolId === undefined) return null;
  //   return registerAnalytics(`/school/${schoolId}`);
  // }, [schoolObj]);

  if (isFetching) {
    return <>Loading...</>;
  }

  if (isError) {
    return <div>Error!</div>;
  }

  const renderGallery = () => {
    if (!hasGallery || hasGallery.length < 3) {
      return <div className={styles.empty__gallery} />;
    }

    return (
      <div className={styles.gallery__container}>
        <SliderGallery
          flkty={flkty}
          galleryImages={hasGallery}
          isFetching={isFetching}
        />
      </div>
    );
  };

  const renderGalleryButtons = () => {
    if (!hasGallery || hasGallery.length < 3) {
      return <></>;
    }

    return (
      <div className={styles.buttons__Container}>
        <div className={styles.buttons_view_port_desktop}>
          <button
            className="gallery_button_global"
            type="button"
            onClick={() => {
              if (flkty.current.selectedIndex <= 0) return null;
              return flkty.current.previous();
            }}
          >
            <BsFillArrowLeftSquareFill />

          </button>
          <button
            className="gallery_button_global"
            type="button"
            onClick={() => {
              if (flkty.current.selectedIndex >= hasGallery.length - 1) return null;
              return flkty.current.next();
            }}
          >
            <BsFillArrowRightSquareFill />

          </button>
        </div>
      </div>
    );
  };

  const renderGoogleReviews = () => {
    if (schoolObj?.public?.is_public) return <></>;

    return (
      <div ref={reviewRef}>
        <GoogleReviews isFetching={isFetching} />
      </div>
    );
  };

  return (
    currentCountryId !== schoolObj.country_id ? <p>School from another country</p> : (
      <div className={styles['footer-container']}>
        <Header />
        <Helmet>
          {schoolObj?.name ? <title>{`${schoolObj?.name} - VacantED`}</title> : null}
          {schoolObj?.name ? <meta name="description" content={`Ficha institucional de ${schoolObj?.name}`} /> : null}
        </Helmet>
        {renderGallery()}
        {renderGalleryButtons()}
        <div className={styles.container}>
          <div className={styles.pageWrapper}>
            <MiniCard
              isFetching={isFetching}
              positioning={hasGallery ? 0 : 0}
            />
            <div className={styles.mainWrapper}>
              <main className={styles.main}>
                <div>
                  {!isLoading
                    && (
                      <UpperLeftActions
                        website={schoolInfo.website}
                        schoolObj={schoolObj}
                        setMsg={setNotLoggedMsg}
                        setNotLoggedAction={setNotLoggedAction}
                      />
                    )}
                </div>
                <div ref={basicInfoRef}>
                  <Description isFetching={isFetching} />
                </div>
                {/* <ProfileSection title="Afinidad Test de Compatibilidad">
                  <AffinityGraph affinity={schoolInfo.schoolAffinity} />
                </ProfileSection> */}
                <div ref={mainFeaturesRef}>
                  <MainFeatures isFetching={isFetching} sections={auxSections} />
                </div>
                <div ref={educationalLevelsRef}>
                  <EducationalLevels isFetching={isFetching} />
                </div>
                <div ref={languagesRef}>
                  <Languages isFetching={isFetching} />
                </div>
                <div ref={religionRef}>
                  <Religion
                    religionDescription={schoolObj.religious_description}
                    religionName={schoolObj.religion.name}
                    isFetching={isFetching}
                  />
                </div>
                <div ref={extracurricularActivitiesRef}>
                  <ExtracurricularActivities isFetching={isFetching} />
                </div>
                <div ref={curricularAdaptationRef}>
                  <CurricularAdaptation isFetching={isFetching} />
                </div>
                <div ref={includedServicesRef}>
                  <IncludedServices isFetching={isFetching} />
                </div>
                <div ref={instalacionsActivitiesRef}>
                  <InstalacionsActivities isFetching={isFetching} />
                </div>
                <div ref={awardsRecognitionsRef}>
                  <AwardsRecognitions isFetching={isFetching} />
                </div>
                <div ref={eventsActivitiesRef}>
                  <EventsActivities isFetching={isFetching} />
                </div>
                <div ref={securitiesMeasuresRef}>
                  <SecuritiesMeasures isFetching={isFetching} />
                </div>
                <div ref={mapRef}>
                  <SchoolProfileMap isFetching={isFetching} />
                </div>
                {renderGoogleReviews()}
              </main>

              <AsideScroll
                props={(
                  <div className={`${styles.asideWrapper} ${styles.sticky}`}>
                    <aside className={styles.aside}>
                      <div
                        className={`${styles.asideContainer} shadow`}
                      >
                        {!!(schoolCanBeContacted || activeExperience) && (
                          <>
                            <div
                              className={bottomBar
                                ? styles.aside__action__btns__mobile
                                : styles.aside__action__btns}
                            >
                              {!!schoolCanBeContacted && !activeExperience && (
                                <div className={styles.rest_actions}>
                                  <ExperienceButton hirachy="primary" size="small" onClick={() => handleAction('visit')}>
                                    Solicitar Entrevista
                                  </ExperienceButton>
                                  <ExperienceButton size="small" onClick={() => handleAction('none')}>
                                    Enviar Consulta
                                  </ExperienceButton>
                                </div>
                              )}
                              {!!schoolCanBeContacted && activeExperience && (
                                <div className={styles.action_stack}>
                                  <div className={styles.main_action}>
                                    <ExperienceButton hirachy="primary" size="small" onClick={() => handleAction('visit')}>
                                      Solicitar Entrevista
                                    </ExperienceButton>
                                  </div>
                                  <div className={styles.rest_actions}>
                                    <ExperienceButton size="small" onClick={() => handleAction('none')}>
                                      Enviar Consulta
                                    </ExperienceButton>
                                    <AddRemoveCandidateButton
                                      schoolId={schoolObj.id}
                                      source={componentFrom?.candidateSource}
                                    />
                                  </div>
                                </div>
                              )}
                              {!schoolCanBeContacted && !!activeExperience && (
                                <AddRemoveCandidateButton
                                  schoolId={schoolObj.id}
                                  source={componentFrom?.candidateSource}
                                />
                              )}
                            </div>
                            <hr />
                          </>
                        )}
                        {auxSections.map((s) => (
                          <button
                            className={styles.asideBtn}
                            type="button"
                            disabled={s.ref === null}
                            onClick={() => scrollToRef(s.ref)}
                            key={s.id}
                          >
                            <p>{s.name}</p>
                          </button>
                        ))}
                        {/* <hr />
                        <div
                          className={styles.asideContainer}
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <div className={`${styles.transparentActionBtn}`}>
                            <button type="button" onClick={handleSearch} className={styles.button}>
                              <p>Ver instituciones cercanas</p>
                            </button>
                          </div>
                        </div> */}
                      </div>
                    </aside>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        {isContactModalOpen && (
          <PrettyContactModal
            onClose={() => handleCloseContactModal()}
            modalAction={selectedModalAction}
            schoolId={schoolObj.id}
            candidateSource={componentFrom?.candidateSource}
          />
        )}
        <CannotPostulateModal
          isOpen={isCannotPostulateModalOpen}
          onRequestClose={(check) => handleCloseCannotPostulateModal(check)}
        />
        <NotLoggedModal Property={notLoggedMsg} Action={notLoggedAction} />
        <Footer />
      </div>
    )
  );
};

export default SchoolProfile;

SchoolProfile.propTypes = {
  id: PropTypes.string.isRequired,
};
