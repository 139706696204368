import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useUserData } from 'hooks/UserContext';
import SchoolsList from '../../../components/schools-List/desktop';
import PageTitle from '../../../components/page-title';
import ExperienceMain from '../../../components/main/view';
import Button from '../../../components/button';
import RemoveCandidateButton from '../../../components/buttons/remove-candidate';
import RequestVisitButton from '../../../components/buttons/request-visit';
import {
  SchoolItemInfoName,
  SchoolItemAddress,
  SchoolItemReligion,
  SchoolItemLanguageFlags,
  SchoolItemPublicType,
} from '../../../components/schools-List/desktop/src/schoolItem';
import SchoolItemContext from '../../../components/schools-List/desktop/src/SchoolItemContext';
import paths from '../../../../../../routes/paths';

import './candidates-styles.scss';

const Recommendations = () => {
  const navigate = useNavigate();
  const {
    activeExperience,
    experienceLoading,
    setSchoolToRegister,
  } = useUserData();

  const onClickConfirmRegistration = (school) => {
    setSchoolToRegister(school);
    navigate(paths.END_EXPERIENCE);
  };

  return (
    <ExperienceMain benefitsBanner>
      <div className="recommendations">
        <PageTitle
          title="Mis candidatos"
          subtitle="Aquí se muestran los centros que guardaste como candidatos en tu búsqueda."
          loading={!!experienceLoading}
        />

        <div className="pre-content">
          <div className="candidate-count">
            {activeExperience?.candidates?.filter(({ active }) => !!active)?.length || 0}
            {' '}
            {activeExperience?.candidates?.filter(({ active }) => !!active)?.length === 1 ? 'candidato' : 'candidatos'}
          </div>
        </div>
        <div className="experience-candidates">
          <SchoolsList>
            {activeExperience?.candidates?.filter(({ active }) => !!active)?.map((result) => (
              <SchoolItemContext.Provider key={uuidv4()} value={result}>
                <div className="school-item">
                  <div className="school-image-container">
                    <Link to={`/school/${result.school.id}?action=visit`}>
                      <img className="school-image" src={result.school.logo || 'https://images.vacanted.com.uy/logos/vacanted_logo_min.png'} alt={result.school.name} />
                    </Link>
                  </div>
                  <div className="school-data-actions-container">
                    <div className="school-info">
                      <SchoolItemInfoName />
                      <div className="school-features">
                        <SchoolItemAddress />
                        <SchoolItemPublicType />
                        <SchoolItemReligion />
                        <SchoolItemLanguageFlags />
                      </div>
                    </div>
                    <div className="school-item__actions">
                      <div className="school-item__actions__main__buttons">
                        <RequestVisitButton
                          schoolId={result.school.id}
                          disabled={
                            result.school.contact_disabled || result.school.public?.is_public
                          }
                        />
                        <Button
                          hirachy="default"
                          size="small"
                          onClick={
                            () => onClickConfirmRegistration(result.school)
                          }
                        >
                          Confirmar inscripción
                        </Button>
                      </div>
                      <div className="school-item__actions__secondary__buttons">
                        <RemoveCandidateButton schoolId={result.school.id} page="candidates" />
                      </div>
                    </div>
                  </div>
                </div>
              </SchoolItemContext.Provider>
            ))}
          </SchoolsList>
        </div>
      </div>
    </ExperienceMain>
  );
};

export default Recommendations;
