/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'hooks/ApplicationContext';
import { useUserData } from 'hooks/UserContext';
import Flow from '../../components/flow';
import FlowContext from '../../components/flow/context';
import searchProfileStep from '../../components/flow/steps/searchProfile';
import paths from '../../../../../routes/paths';
import useExperience from '../../hooks/useExperience';

const AddSearchProfile = () => {
  const navigate = useNavigate();
  const {
    loadingContext: { setPageLoading },
    snackbarContext: { setSnackbar, closeSnackbar },
  } = useAppContext();
  const {
    activeExperience,
    updateActiveExperience,
  } = useUserData();

  const [newSearchProfile, setNewSearchProfile] = useState({});

  const { finishedLoading, onCreateSearchProfile, ...optionData } = useExperience();
  const [currentPageId, setCurrentPageId] = useState();

  useEffect(() => {
    if (typeof window.Tawk_API?.hideWidget === 'function') window.Tawk_API.hideWidget();

    return () => {
      if (typeof window.Tawk_API?.showWidget === 'function') window.Tawk_API.showWidget();
    };
  }, []);

  useEffect(() => {
    if (activeExperience === null) {
      navigate(paths.NEW_EXPERIENCE);
    } else if (activeExperience) {
      const experienceFlowData = {
        onlyPreschool: !!activeExperience.context?.basic?.levels?.only_preschool,
        alreadyInterestedInOpportunities:
          !!activeExperience.context?.receive_opportunities?.interested,
      };
      setNewSearchProfile((prevExperience) => ({
        ...experienceFlowData,
        ...prevExperience,
      }));
    }
  }, [activeExperience]);

  useEffect(() => {
    if (currentPageId) {
      window.history.replaceState(null, '', `${window.location.pathname}?page=${currentPageId}`);
    }
  }, [currentPageId]);

  const calculateOnlyPublicUpdate = (publics, currentExp) => {
    const currentOnlyPublicSchools = currentExp.onlyPublicSchools;
    const computedOnlyPublicSchools = !!publics?.every(
      (selectedPublicId) => !!optionData.typeSchool?.find(
        ({ value: publicId, isPublic }) => publicId === selectedPublicId && isPublic,
      ),
    );
    if ((currentOnlyPublicSchools && !computedOnlyPublicSchools)
      || (!currentOnlyPublicSchools && computedOnlyPublicSchools)) {
      return computedOnlyPublicSchools;
    }
    return null;
  };

  const onChangeStepValue = (key, value, currentExp, merge) => {
    // console.log('onChangeStepValue2', key, value, currentExp, merge);
    const update = { };
    if (key === 'typeSchool') {
      const newOnlyPublicSchools = calculateOnlyPublicUpdate(value, currentExp);
      if (typeof newOnlyPublicSchools === 'boolean') {
        update.onlyPublicSchools = newOnlyPublicSchools;
      }
    }
    setNewSearchProfile((prevExperience) => ({
      ...prevExperience,
      [key]: merge ? { ...prevExperience[key], ...value } : value,
      ...update,
    }));
  };

  const steps = [
    searchProfileStep(optionData, onChangeStepValue),
  ];

  useEffect(() => {
    // Set default langs
    if (optionData.languages?.length) {
      const nativeLangsIds = optionData.languages.filter(
        ({ isNative }) => !!isNative,
      ).map(({ value }) => value);
      setNewSearchProfile((prevExperience) => ({
        languages: nativeLangsIds,
        languagesClass: nativeLangsIds,
        ...prevExperience,
      }));
    }
  }, [optionData.languages]);

  useEffect(() => {
    if (newSearchProfile) {
      // console.log('newProfile', newSearchProfile);
    }
  }, [newSearchProfile]);

  const onEnd = async (newProf) => {
    setPageLoading(true);
    onCreateSearchProfile(activeExperience.id, newProf, optionData)
      .then(updateActiveExperience)
      .then(() => {
        setSnackbar({
          hirachy: 'success',
          text: 'El perfil de búsqueda fue creado correctamente.',
          action: 'Entendido',
          onClick: closeSnackbar,
        });
        navigate(paths.EXPERIENCE_RECOMMENDATIONS);
      })
      .catch(() => {
        setSnackbar({
          hirachy: 'error',
          text: 'Ocurrió un error al guardar tu perfil de búsqueda.',
          action: 'Entendido',
          onClick: closeSnackbar,
        });
      })
      .finally(() => setPageLoading(false));
  };

  return (
    <FlowContext.Provider
      value={{
        isFlow: true,
        currentData: newSearchProfile,
        setCurrentPageId,
        steps,
        onEnd: () => onEnd(newSearchProfile),
        onExit: () => navigate(paths.EXPERIENCE_RECOMMENDATIONS),
      }}
    >
      {finishedLoading && <Flow />}
    </FlowContext.Provider>
  );
};

export default AddSearchProfile;
