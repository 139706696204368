import countryService from './countryService';

const TEXT_KEYS = {
  INTERACTION_PENDING_VALIDATION: 'INTERACTION_PENDING_VALIDATION',
  INTERACTION_ADDED_CANDIDATE: 'INTERACTION_ADDED_CANDIDATE',
  INTERACTION_CREATED: 'INTERACTION_CREATED',
  INTERACTION_FAILED: 'INTERACTION_FAILED',
  INTERACTION_ALREADY_CONTACTED: 'INTERACTION_ALREADY_CONTACTED',
  INTERACTION_ALREADY_CHATTING: 'INTERACTION_ALREADY_CHATTING',
  INTERACTION_ALREADY_REQUESTED_INTERVIEW: 'INTERACTION_ALREADY_REQUESTED_INTERVIEW',
};

const TEXTS = {
  [TEXT_KEYS.INTERACTION_PENDING_VALIDATION]: {
    uy: () => 'Te enviamos un correo para validar que sos vos.',
    ar: () => 'Te enviamos un correo para validar que sos vos.',
    default: () => 'Te enviamos un correo para validar que eres tú.',
  },
  [TEXT_KEYS.INTERACTION_ADDED_CANDIDATE]: {
    params: ['schoolName'],
    default: (params) => `Enviamos tu solicitud a ${params.schoolName} y lo agregamos a tu listado de candidatos. Te avisaremos por email cuando el centro responda.`,
  },
  [TEXT_KEYS.INTERACTION_CREATED]: {
    params: ['schoolName'],
    default: (params) => `Enviamos tu solicitud a ${params.schoolName}. Te avisaremos por email cuando el centro responda.`,
  },
  [TEXT_KEYS.INTERACTION_FAILED]: {
    default: () => 'No pudimos procesar tu solicitud. Por favor, intenta nuevamente.',
  },
  [TEXT_KEYS.INTERACTION_ALREADY_CONTACTED]: {
    default: () => 'Ya enviaste una consulta a este centro. Debes aguardar su respuesta.',
  },
  [TEXT_KEYS.INTERACTION_ALREADY_CHATTING]: {
    default: () => 'Ya tienes una conversación con este centro. Entra a tu área privada para continuar la comunicación.',
  },
  [TEXT_KEYS.INTERACTION_ALREADY_REQUESTED_INTERVIEW]: {
    default: () => 'Ya solicitaste una entrevista con este centro. Debes aguardar su respuesta.',
  },
};

const getLocalizedText = (textsKey, params = {}) => {
  const textsObject = TEXTS[textsKey];
  if (!textsObject) {
    throw new Error(`Texts key not found: ${textsKey}`);
  }
  if (textsObject.params?.length) {
    textsObject.params.forEach((requiredParam) => {
      if (!params[requiredParam]) {
        throw new Error(`Missing required parameter: ${requiredParam}`);
      }
    });
  }
  const countryCode = countryService.getCurrentCountryCode();
  return textsObject[countryCode] ? textsObject[countryCode](params) : textsObject.default(params);
};

export default {
  TEXT_KEYS,
  getLocalizedText,
};
